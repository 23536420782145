<template>
    <div class="d-flex align-center catalog-banner">
        <div id="heading-segment" class="d-flex flex-column">
            <p class="ma-0 half-width hero-heading font-weight-semi-bold white-color-text" align="left">{{ heading_name }}</p>
            <p class="ma-0 body-text font-weight-medium white-color-text" v-if="subheading_name != null" align="left">{{ subheading_name }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryHeaderWebComponent',
    props: {
        heading_name: {
            type: String,
            required: false
        },
        subheading_name: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            screenWidth: window.innerWidth,
        }
    },
    watch: {
		screenWidth(newWidth, oldWidth) {
			console.log('Screen Width Changed:', newWidth);
		},
	},
    mounted() {
        // template stuff.
        this.screenWidth = window.innerWidth;
        let headingDiv = document.getElementById('heading-segment');

        let padding = this.screenWidth > 1264 ? (this.screenWidth - 1264) / 2 : 32;

        headingDiv.style.paddingLeft = padding + 'px';
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';
.catalog-banner {
    width: 100vw;
    aspect-ratio: 8 / 1;
    background-image: url('https://d3vsnl8idgwrlw.cloudfront.net/catalog_page_banner.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (max-width: 600px) {
        display: none;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        
    }

    @media (min-width: 1265px) {
        
    }
}

#heading-segment {
    width: 100%;
}
</style>